import { notification } from 'antd'
// import { useSelector } from 'react-redux'
// import { useFirestoreConnect } from 'react-redux-firebase'
// import { useFirestore } from 'react-defux-firebase'
// import axios from 'axios'

// import moment from 'moment'

import _ from 'lodash'
import { USER } from 'constant'
import { deviceType, osName, browserName, mobileVendor, mobileModel } from 'react-device-detect'

import * as ko from 'locales/ko-KR'
import * as en from 'locales/en-US'
import { firebaseAuth, fireStore, fireBase, firebaseFunctions, analytics } from '../../firebase'
// const REGION = 'asia-northeast3';
// import admin from '../../firebase/admin'
if (process.env.NODE_ENV === 'development') {
  // firebaseFunctions.useFunctionsEmulator('http://localhost:5001')
}
let locale = ko
if (process.env.REACT_APP_LOCALE === 'en-US') {
  locale = en
}
export async function login(email, password) {
  return firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then((response) => {
      const { user } = response
      // console.log(user)
      userLoggedInOut(user, 'login')
      createLastLoginAt(user)
      const token = btoa(Math.random().toString()).substr(10, 30)
      // const token = Buffer.from(Math.random().toString()).toString("base64").substr(0, 10)
      console.log(token)
      createLoginToken(user, token)
      analytics.setUserId(user.email)

      return response
    })
    .catch((error) => {
      console.log(error)
      if (error.code === 'auth/user-not-found') {
        notification.warning({
          message: locale.messages['auth.error.userNotFound.title'],
          description: locale.messages['auth.error.userNotFound.description'],
          duration: 5,
        })
      } else if (error.code === 'auth/wrong-password') {
        notification.warning({
          message: locale.messages['auth.error.password.title'],
          description: locale.messages['auth.error.password.description'],
          duration: 5,
        })
      } else {
        notification.warning({
          message: error.code,
          description: error.description,
          duration: 5,
        })
      }
    })
}

export async function logout() {
  const user = firebaseAuth.currentUser
  // console.log(user)
  if (user) {
    const isOfflineForDatabase = {
      state: 'logout',
      last_changed: fireBase.database.ServerValue.TIMESTAMP,
    }
    const userStatusDatabaseRef = fireBase.database().ref(`/status/${user.uid}`)
    await userStatusDatabaseRef.set(isOfflineForDatabase)
    const userStatus = fireStore.doc(`/users/${user.uid}`)
    const isOfflineForFirestore = {
      state: 'offline',
      lastOfflineAt: fireBase.firestore.FieldValue.serverTimestamp(),
    }
    await userStatus.set(isOfflineForFirestore, { merge: true })
    console.log('userstatus set offline')
    await userLoggedInOut(user, 'logout')
    console.log('loggin logout')
  }

  return firebaseAuth.signOut().then(() => {
    localStorage.removeItem('signInToken')
    return true
  })
  //   console.log(result)
  // })
  // const data = yield call(rsFirebase.auth.signOut)
  // return true
}

// eslint-disable-next-line no-unused-vars
export async function userLoggedInOut(user, type) {
  const now = fireBase.firestore.Timestamp.now()
  const timestamp = now.seconds

  let logs = []
  const userRef = await fireStore.collection('users').doc(user.uid)
  await userRef.get().then((profile) => {
    const userFields = profile.data()
    // console.log(userFields)
    // console.log(_.has(userFields, type))
    if (_.has(userFields, type)) {
      // console.log('user has', type)
      logs = userFields[type]
    }
  })

  logs.push(timestamp)
  let data = { [type]: logs }
  // const doc = `${user.uid}/logs/${timestamp}/`
  // console.log(data)
  await fireStore
    .collection('users')
    .doc(user.uid)
    .set(data, {
      merge: true,
    })
    .then(() => true)
    .catch(() => {
      return false
    })

  // return true
}

export async function createLastLoginAt(user) {
  const now = fireBase.firestore.Timestamp.now()
  const timestamp = now.seconds
  const data = { lastLoggedInAt: timestamp }
  fireStore
    .collection('users')
    .doc(user.uid)
    .set(data, {
      merge: true,
    })
    .then(() => true)
    .catch(() => {
      // console.log(error)
      return false
    })
  return true
}

export async function createLoginToken(user, token) {
  localStorage.setItem('signInToken', token)
  const data = { loginToken: token }
  fireStore
    .collection('users')
    .doc(user.uid)
    .set(data, {
      merge: true,
    })
    .then(() => true)
    .catch(() => {
      // console.log(error)
      return false
    })
  return true
}

export async function register(
  email,
  password,
  payload,

  // email,
  // password,
  // name,
  // nickname,
  // phone,PbL3B31V7cWFyQJVarXNsY7zcmR2
  // department,
  // work,
  // attendance,
  // type,
) {
  return firebaseAuth
    .createUserWithEmailAndPassword(email, password)
    .then((response) => {
      if (response.user) {
        const now = fireBase.firestore.Timestamp.now()
        const { uid } = response.user

        // console.log(payload)
        delete payload.confirm
        // console.log(payload)

        const token = btoa(Math.random().toString()).substr(10, 30)
        localStorage.setItem('signInToken', token)

        const data = {
          // ...payload,
          role: 'user',
          email,
          profile: payload.profile,
          // phone,
          // name,
          // nickname,
          // department,
          // work,
          // attendance: 'online',
          // type: 'listener',
          acceptableSchedules: payload.acceptableSchedules,
          defaultSchedule: payload.defaultSchedule,
          surveyAllowed: true,
          chatAllowed: true,
          registeredAt: now,
          blocked: false,
          allowed: true,
          loginToken: token,
        }
        console.log(data)
        if (email === 'admin@pageone.works') {
          data.role = 'admin'
        }
        fireStore
          .collection('users')
          .doc(uid)
          .set(data)
          .then(() => {
            // console.log(res)
            return true
          })
          .catch(() => {
            // console.log(error)
          })
      }
      return true
    })
    .catch((error) => {
      let { message } = error
      switch (error.code) {
        case 'auth/claims-too-large':
          message = 'Sign up claims too large. '
          break
        case 'auth/email-already-exists':
        case 'auth/email-already-in-use':
          message = ' Email already exists'
          break
        case 'auth/invalid-email':
          message = 'Invalid email address'
          break
        case 'auth/weak-password':
          message = 'Weak password'
          break
        default:
          break
      }
      notification.warning({
        // message: error.code,
        message: 'Sign up error',
        description: message,
        duration: 10,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      // eslint-disable-next-line no-unused-vars
      const unsubscribe = auth.onAuthStateChanged((userAuth) => {
        // console.log('getUserAuth', userAuth)
        if (userAuth) {
          const device = {
            deviceType,
            osName,
            browserName,
            mobileVendor,
            mobileModel,
          }

          const userStatusDatabaseRef = fireBase.database().ref(`/status/${userAuth.uid}`)
          const userStatusFirestoreRef = fireStore.doc(`/status/${userAuth.uid}`)
          const userStatus = fireStore.doc(`/users/${userAuth.uid}`)
          const isOfflineForDatabase = {
            state: 'offline',
            last_changed: fireBase.database.ServerValue.TIMESTAMP,
          }

          const isOnlineForDatabase = {
            state: 'online',
            last_changed: fireBase.database.ServerValue.TIMESTAMP,
          }
          const isOfflineForFirestore = {
            state: 'offline',
            lastOfflineAt: fireBase.firestore.FieldValue.serverTimestamp(),
          }

          const isOnlineForFirestore = {
            state: 'online',
            lastOnlineAt: fireBase.firestore.FieldValue.serverTimestamp(),
          }
          const onlineDeviceForFirestore = {
            device: device,
          }
          fireBase
            .database()
            .ref('.info/connected')
            .on('value', (snapshot) => {
              if (snapshot.val() === false) {
                userStatusFirestoreRef.set(isOfflineForFirestore)
                // userStatus.set(isOfflineForFirestore, { merge: true })
                return
              }
              userStatusDatabaseRef
                .onDisconnect()
                .set(isOfflineForDatabase)
                .then(() => {
                  userStatusDatabaseRef.set(isOnlineForDatabase)
                  userStatusFirestoreRef.set(isOnlineForFirestore, { merge: true })
                  userStatus.set(onlineDeviceForFirestore, { merge: true })
                })
            })
        }
        userLoaded = true
        unsubscribe()
        resolve(userAuth)
      }, reject)
    })
  }

  // const getProfileData = async user => {
  //   // let mergedUser = {}
  //   const profileData = await fireStore
  //     .collection('users')
  //     .doc(user.uid)
  //     .onSnapshot(profile => {
  //       console.log(profile.data())
  //       const userFields = profile.data()
  //       if (!userFields) {
  //         return user
  //       }
  //       // console.log(userFields)

  //       const mergedUser = Object.assign(user, {
  //         id: user.uid,
  //         profile: userFields.profile,
  //         allowed: userFields.allowed,
  //         role: userFields.role,
  //       })
  //       return mergedUser
  //     })
  //   return profileData
  // }

  const loggedUser = await getCurrentUser(firebaseAuth)
  // const userData = await getProfileData(loggedUser)
  // console.log(userData)
  return loggedUser
}

export async function sendFindPasswordMail(emailAddress) {
  return firebaseAuth
    .sendPasswordResetEmail(emailAddress)
    .then(() => {
      // Email sent.
      return true
    })
    .catch((error) => {
      // An error happened.
      console.log(error)
      return false
    })
}

export async function updateUser(data) {
  // console.log(firebaseAuth.currentUser)
  // console.log(data)
  fireStore
    .collection('users')
    .doc(data.id)
    .set(data.values, {
      merge: true,
    })
    .then(() => true)
    .catch(() => {
      // console.log(error)
      return false
    })
  return true
}

export async function deleteUser(data) {
  if (firebaseAuth.currentUser.role === USER.TYPE.ADMIN) {
    const deleteUserFunc = firebaseFunctions.httpsCallable('deleteUser')
    deleteUserFunc(data).then(() => {
      // console.log(result)
    })
    // firebaseAuth.currentUser.getIdToken().then(authToken => {
    //   const headers = { 'Authorization': `Bearer ${authToken}` }
    //   const body = { uid: firebaseAuth.currentUser.uid }
    //   axios.post(
    //     'http://localhost:5001/hanuvina-5e3bb/asia-northeast3/helloWorld',
    //     body,
    //     { 'headers': headers }
    //   )
    // })
    // console.log('deleteUser', data)
    // fireStore
    //   .collection('users')
    //   .doc(data.id)
    //   .delete()
    //   .then(() => true)
    //   .catch(error => {
    //     console.log(error)
    //     return false
    //   })
    return true
  }
  return false
}

export async function deactivate() {
  await fireStore
    .collection('sessions')
    .where('active', '==', true)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.exists) {
          // console.log(doc.data())
          doc.ref.update({ active: false })
          // doc.set({ active: false }, {
          //   merge: true,
          // })
        }
      })
    })
  return true
}

export async function currentSession() {
  const getSessionData = async () => {
    const sessionRef = await fireStore.collection('sessions').where('active', '==', true)
    let session = null
    await sessionRef.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.exists) {
          session = doc.data()
          // console.log(doc)
          session.id = doc.id
          return true
        }
        return false
      })
    })
    return session
  }
  return getSessionData()
}

export async function getSchedules() {
  const ref = fireStore.collection('schedules')
  let schedulesMap = {}
  await ref.get().then((snapshot) => {
    snapshot.forEach((doc) => {
      if (doc.exists) {
        const data = doc.data()
        data.id = doc.id
        schedulesMap[doc.id] = data
        return true
      }
      return false
    })
  })
  return schedulesMap
}

export async function getUserProfileField() {
  const sessionRef = await fireStore.collection('userProfileField').orderBy('order', 'asc')
  let userProfileFields = []
  await sessionRef.get().then((snapshot) => {
    snapshot.forEach((doc) => {
      if (doc.exists) {
        const data = doc.data()
        data.id = doc.id
        userProfileFields.push(data)
        return true
      }
      return false
    })
  })
  return userProfileFields
}
